module.exports = [{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"resetCSS":true,"isUsingColorMode":true},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"custom":[{"name":"Inter","file":"/css/fonts.css"},{"name":"Poppins","file":"/css/fonts.css"},{"name":"DM Sans","file":"/css/fonts.css"},{"name":"Helvetica Neue","file":"/css/fonts.css"},{"name":"SF Pro Text","file":"/css/fonts.css"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"41cf45c12874ffe93874db166e343bcc","pageViews":"all"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
